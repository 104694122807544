<script setup lang="ts">
import { NuxtLink } from "#components";

const props = withDefaults(
  defineProps<{ item: SliderItem; loading?: "eager" | "lazy" }>(),
  { loading: "lazy" }
);

const { isMobile } = useUserDevice();

const color = computed(() =>
  props.item.color
    ? {
        blue: "bg-blue",
        rose: "bg-rose",
        green: "bg-green",
        yellow: "bg-yellow",
        darkblue: "bg-darkblue",
        grey: "bg-grey",
        copperfield: "bg-copperfield",
        mongoose: "bg-mongoose",
      }[props.item.color]
    : "bg-blue-500"
);

const isExternalLink = computed(() => props.item.link?.startsWith("http"));

const mobileTitle = computed(() => props.item.short_title || props.item.title);
const desktopTitle = computed(() => props.item.title || props.item.short_title);
</script>

<template>
  <div
    class="page-slider-item w-full h-full flex flex-col items-stretch justify-stretch"
  >
    <div class="page-slider-item__wrapper grow relative">
      <component
        :is="item.link ? NuxtLink : 'div'"
        :to="item.link"
        :target="isExternalLink ? '_blank' : undefined"
        :rel="isExternalLink ? 'noopener noreferrer' : undefined"
        class="page-slider-item__image absolute z-10 inset-0 w-full h-full"
      >
        <NuxtPicture
          :key="`${props.item.id}-${isMobile ? 'mobile' : 'desktop'}`"
          v-if="props.item.image"
          provider="directus"
          :src="props.item.image.id"
          :width="isMobile ? 353 : 1110"
          :height="isMobile ? 240 : 463"
          :alt="props.item.image.description || props.item.title"
          :img-attrs="{
            class: 'object-cover w-full h-full',
          }"
          sizes="290px xs:353px sm:576px md:692px lg:790px xl:1110px xxl:1514px"
          :loading="props.loading"
          quality="65"
          preload
        />
      </component>

      <div
        class="page-slider-item__content absolute z-20 bottom-0 inset-x-0 p-5 lg:p-10 flex flex-col items-start justify-end pointer-events-none"
      >
        <div
          class="page-slider-item__content-wrapper px-4 py-2 rounded-t-2xl rounded-e-2xl text-black"
          :class="[color]"
        >
          <h2 class="text-base leading-5 lg:text-lg lg:leading-8">
            {{ isMobile ? mobileTitle : desktopTitle }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
